/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-use-before-define */
import 'babel-polyfill';
import './assets/styles/app.css';
import './assets/styles/modal.css';
import 'blueimp-gallery/css/blueimp-gallery.min.css';
import 'blueimp-gallery/js/blueimp-gallery.min';
import MicroModal from 'micromodal';
import images from './assets/images/*.png';
import {
  IsNotEmpty, ValidateNested, IsNumber,
} from 'class-validator';
import axios from 'axios';
import blueimp from './config/index';

document.pin = '';
MicroModal.init();

export class FileDownloadInstruction {
  @IsNotEmpty()
  s3GetObjectUrl!: string;

  constructor(init?: Partial<FileDownloadInstruction>) {
    Object.assign(this, init);
  }
}

export class DownloadEvidenceShareResponse {
  @IsNotEmpty()
  shareId!: string;

  /**
   * UUID for this download request.
   */
  @IsNotEmpty()
  downloadId!: string;

  @ValidateNested()
  evidenceFiles: { [filename: string]: FileDownloadInstruction } = {};

  @IsNumber()
  downloadUrlExpirySeconds!: number;

  constructor(init?: Partial<DownloadEvidenceShareResponse>) {
    Object.assign(this, init);
  }
}

export class EvidenceShareMetaData {
  title!: string;

  startDate!: string;

  endDate!: string;

  mediaType!: string;

  additionalNarrative!: string;
}

const pinInputs = document.getElementsByClassName('pin-input')!;
const pinButton = document.getElementById('pin-button')!;
const authentication = document.getElementById('authentication')!;
const gallery = document.getElementById('gallery')!;
const modalMessage = document.getElementById('modal-message')!;
const loadingContainer = document.getElementById('loading-container')!;
const printButton = document.getElementById('print-button')!;

const title = document.getElementById('title')!;
const appInfo = document.getElementById('app-info')!;
const date = document.getElementById('date')!;
const documentationType = document.getElementById('documentation-type')!;
const additionalNarrative = document.getElementById('additional-narrative')!;
const imageArray = [];

let loading = false;
const shareId = window.location.pathname.split('/').pop();
// const apiUrl = 'https://api.dev.secure.docusafe.app';
// const apiUrl = 'https://api.secure.docusafe.app';
const apiUrl = process.env.API_URL;
const apiKey = 'WnmmVqKgtuBfcxFluolne4GLB4dfMh7MRnlswNAyRrjriCcZwcbvDZoTya5gjIph';

if (!shareId) {
  modalMessage.innerHTML = 'No share found!';
  MicroModal.show('modal-1');
}

authentication.addEventListener('paste', (e) => {
  e.stopPropagation();
  e.preventDefault();
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData('Text');
  if (!isNaN(pastedData) && pastedData.length === 6) {
    document.pin = pastedData;
    for (let i = 0; i < pinInputs.length; i += 1) {
      const element: any = pinInputs[i];
      element.value = pastedData.charAt(i);
      (pinInputs[0] as HTMLElement).blur();
    }
  }
});

pinButton.addEventListener('click', () => {
  if (document.pin.length !== 6) {
    modalMessage.innerHTML = 'Please enter a valid pin code!';
    return MicroModal.show('modal-1');
  }
  return getShareDownload();
});

printButton.addEventListener('click', () => {
  const tempTitle = document.title;
  document.title = 'Download Share.pdf';
  window.print();
  document.title = tempTitle;
});

export function getShareDownload() {
  axios.post(
    `${apiUrl}/shares/${shareId}/download`,
    {
      userSecret: document.pin,
    },
    { headers: { 'x-api-key': apiKey } },
  ).then(async (response: any) => {
    loading = true;
    loadingContainer.style.opacity = '1';
    loadingContainer.style.height = 'auto';
    authentication.style.opacity = '0';
    setTimeout(() => authentication.parentNode!.removeChild(authentication), 500);
    const files = response.data.evidenceFiles;
    await checkFiles(files);
    await initBlueImp();
  }).catch((error) => {
    modalMessage.innerHTML = `${error.response.status}: ${error.response.data.error || 'Something went wrong!'}`;
    MicroModal.show('modal-1');
  });
}

function addFileToGallery(file: FileDownloadInstruction) {
  if (loading) {
    loadingContainer.parentNode!.removeChild(loadingContainer);
    loading = false;
  }
  const div = document.getElementById('links');
  const url = file.s3GetObjectUrl;
  if (!url.includes('metaData.json')) {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    const img = document.createElement('img');
    const printImg = document.createElement('prinTpreview');
    if (url.includes('mp4')) {
      // file is a video
      a.setAttribute('type', 'video/mp4');
      a.setAttribute('data-sources', `[{"href": ${url}, "type": "video/mp4"}]`);
      img.setAttribute('src', images.video2);
      img.setAttribute('id', 'videoImage');
    } else {
      //   // file is a photo
      img.setAttribute('src', url);
      img.setAttribute('id', 'printImage');
      printImg.setAttribute('src', url);
      imageArray.push(img);
    }
    a.appendChild(img);
    div!.appendChild(a);
  }
}

const asyncIntervals: any[] = [];

const runAsyncInterval = async (cb: Function, interval: number, intervalIndex: number) => {
  await cb();
  if (asyncIntervals[intervalIndex]) {
    setTimeout(() => runAsyncInterval(cb, interval, intervalIndex), interval);
  }
};

const setAsyncInterval = (cb: Function, interval: number) => {
  const intervalIndex = asyncIntervals.length;
  asyncIntervals.push(true);
  runAsyncInterval(cb, interval, intervalIndex);
  return intervalIndex;
};

const clearAsyncInterval = (intervalIndex: number) => {
  if (asyncIntervals[intervalIndex]) {
    asyncIntervals[intervalIndex] = false;
  }
};

function checkFile(file: FileDownloadInstruction): Promise<FileDownloadInstruction> {
  return new Promise(async (resolve) => {
    let waitingForFile: boolean = await checkWaitingForFile(file);
    if (waitingForFile) {
      const intervalIndex = setAsyncInterval(async () => {
        if (waitingForFile) {
          waitingForFile = await checkWaitingForFile(file);
        } else {
          clearAsyncInterval(intervalIndex);
          addFileToGallery(file);
          resolve(file);
        }
      }, 1000);
    } else {
      addFileToGallery(file);
      resolve(file);
    }
  });
}

function checkFiles(files: { [filename: string]: FileDownloadInstruction }) {
  return new Promise(async (resolve) => {
    for (const i in files) {
      await checkFile(files[i]);
    }
    gallery.style.opacity = '1';
    appInfo.style.opacity = '1';
    resolve();
  });
}

function checkWaitingForFile(file: FileDownloadInstruction): Promise<boolean> {
  return new Promise((resolve) => {
    axios.get(file.s3GetObjectUrl).then((response: any) => {
      if (file.s3GetObjectUrl.includes('metaData.json')) {
        setMetaData(response.data);
      }

      resolve(false);
    }).catch(() => {
      resolve(true);
    });
  });
}

function upperCaseFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function lowerCaseAllWordsExceptFirstLetters(string: string) {
  return string.replace(/\w\S*/g, function (word: string) {
    return word.charAt(0) + word.slice(1).toLowerCase();
  });
}

function setMetaData(metaData: EvidenceShareMetaData): void {
  title.innerText = metaData.title || '';
  date.innerText = metaData.startDate || metaData.endDate ? `(${metaData.startDate} - ${metaData.endDate})` : '';
  documentationType.innerText = metaData.mediaType ? `DocumentationType: ${upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(metaData.mediaType))}` : '';
  additionalNarrative.innerText = metaData.additionalNarrative;
}

function initBlueImp() {
  return new Promise((resolve) => {
    document.getElementById('links')!.onclick = function handleLink(ev) {
      const self: any = this;
      const event: any = ev || window.event;
      const target = event.target || event.srcElement;
      if (['A', 'IMG'].includes(target.nodeName)) {
        const link = target.src ? target.parentNode : target;
        const options = {
          index: link,
          event,
          carousel: true,
          startSlideshow: false,
        };
        const links = self.getElementsByTagName('a');
        blueimp.Gallery(links, options);
      }
    };
    resolve();
  });
}
